import { render, staticRenderFns } from "./Services.vue?vue&type=template&id=6cb3f455&scoped=true&"
import script from "./Services.vue?vue&type=script&lang=ts&"
export * from "./Services.vue?vue&type=script&lang=ts&"
import style0 from "./Services.vue?vue&type=style&index=0&id=6cb3f455&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cb3f455",
  null
  
)

component.options.__file = "Services.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib'
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VProgressCircular } from 'vuetify/lib'
import { VResponsive } from 'vuetify/lib'
import { VWindow } from 'vuetify/lib'
import { VWindowItem } from 'vuetify/lib'
installComponents(component, {
  VAlert,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VFlex,
  VLayout,
  VProgressCircular,
  VResponsive,
  VWindow,
  VWindowItem,
})
