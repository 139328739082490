import { render, staticRenderFns } from "./Responsabilities.vue?vue&type=template&id=192c6a44&scoped=true&"
import script from "./Responsabilities.vue?vue&type=script&lang=ts&"
export * from "./Responsabilities.vue?vue&type=script&lang=ts&"
import style0 from "./Responsabilities.vue?vue&type=style&index=0&id=192c6a44&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "192c6a44",
  null
  
)

component.options.__file = "Responsabilities.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VWindow } from 'vuetify/lib'
import { VWindowItem } from 'vuetify/lib'
installComponents(component, {
  VCard,
  VCardText,
  VFlex,
  VLayout,
  VWindow,
  VWindowItem,
})
