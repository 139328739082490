










































































































































































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class ContactComponent extends Vue {
	public mailSender: string = ''
	public mailTitle: string = ''
	public mailMessage: string = ''
	public showEmailErrorMessageBox = false
	public showEmptyErrorMessageBox = false
	public showSuccessMessageBox = false
	public showErrorMessageBox = false
	public isLoading = false
	public dialogIsLoading = false
	public applicationForm = false
	public applicationFormName: string = ''
	public applicationFormMail: string = ''
	public applicationFormPhone: string = ''
	public applicationFormCity: string = ''
	public applicationFormFile: any = null
	public applicationFormMessage: string = ''
	public successBox = false
	public errorBox = false
	public errorBoxMessage: string = ''
	public fileLabel: string = 'Choisissez un fichier à télécharger (.pdf)'
	private successTimer: any
	private errorTimer: any
	private emptyErrorTimer: any
	private emailErrorTimer: any
	private fileSize: boolean = false
	private fileType: boolean = false
	private fileContent: any = ''

	public handleFileUpload(): void {
		this.applicationFormFile = this.$refs.applicationFormFile
		this.fileLabel = 'Fichier (.pdf) : ' + this.applicationFormFile.files[0].name
		if (this.applicationFormFile.files[0].size < 2000000) {
			this.fileSize = true
		} else {
			this.fileSize = false
		}
		if (this.applicationFormFile.files[0].type === 'application/pdf') {
			this.fileType = true
		} else {
			this.fileType = false
		}
		const reader = new FileReader()
		reader.readAsDataURL(this.applicationFormFile.files[0])
		const me = this
		reader.onload = () => {
			this.fileContent = reader.result
		}
	}

	private sendApplication(): void {
		this.successBox = false
		this.errorBox = false
		if (this.applicationFormName === '' ||
			this.applicationFormMail === '' ||
			this.applicationFormPhone === '' ||
			this.applicationFormCity === '' ||
			this.applicationFormFile === null ||
			this.fileContent === '' ||
			this.applicationFormMessage === '') {
			this.errorBox = true
			this.errorBoxMessage = 'Assurez-vous d\'avoir remplis tous les champs du formulaire.'
		} else if (!(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.applicationFormMail))) {
			this.errorBox = true
			this.errorBoxMessage = 'Veuillez entrer une adresse courriel valide.'
		} else if (this.fileSize === false) {
			this.errorBox = true
			this.errorBoxMessage = 'La taille du fichier est trop grande. (Taille maximale : 2Mo)'
		} else if (this.fileType === false) {
			this.errorBox = true
			this.errorBoxMessage = 'Le fichier doit être de format PDF.'
		} else {
			this.dialogIsLoading = true // We show a loading icon while we send our request.
			axios.post('lib/application.php', {
				name: this.applicationFormName,
				mail: this.applicationFormMail,
				phone: this.applicationFormPhone,
				file: this.fileContent,
				city: this.applicationFormCity,
				message: this.applicationFormMessage
			})
			.then((response) => {
				this.dialogIsLoading = false
				this.successBox = true
			})
			.catch((error) => {
				this.dialogIsLoading = false
				this.errorBox = true
				this.errorBoxMessage = 'Il y a eu une erreur lors de l\'envoie de la candidature. Réessayer plus tard !'
			})
			// We also need to reset our input fields.
			this.applicationFormName = ''
			this.applicationFormMail = ''
			this.applicationFormPhone = ''
			this.applicationFormCity = ''
			this.applicationFormFile = null
			this.fileContent = ''
			this.fileLabel = 'Choisissez un fichier à télécharger (.pdf)'
			this.applicationFormMessage = ''
		}
	}

	private sendMessage(): void {
		// We make sure to clear our intervals and messages first if the user made previous attempt.
		clearInterval(this.successTimer)
		clearInterval(this.emptyErrorTimer)
		clearInterval(this.emailErrorTimer)
		clearInterval(this.errorTimer)
		this.showEmailErrorMessageBox = false
		this.showEmptyErrorMessageBox = false
		this.showSuccessMessageBox = false
		this.showErrorMessageBox = false
		// Then we start validating.
		this.showEmptyErrorMessageBox = (this.mailSender === '' || this.mailTitle === '' || this.mailMessage === '')
		? true : false
		if (this.showEmptyErrorMessageBox === false) {
			this.showEmailErrorMessageBox = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
			.test(this.mailSender) ? false : true
		}
		// If all the data is valid, we can send the email.
		if (this.showEmptyErrorMessageBox === false && this.showEmailErrorMessageBox === false) {
			this.isLoading = true // We show a loading icon while we send our request.
			axios.post('lib/mail.php', {
				mailSender: this.mailSender,
				mailTitle: this.mailTitle,
				mailMessage: this.mailMessage
			})
			.then((response) => {
				this.isLoading = false
				this.showSuccessMessageBox = true
			})
			.catch((error) => {
				this.isLoading = false
				this.showErrorMessageBox = true
			})
			// We also need to reset our input fields.
			this.mailSender = ''
			this.mailTitle = ''
			this.mailMessage = ''
		}
		// We make sure to clear the messages with a timer.
		this.successTimer = setInterval(() => {this.showSuccessMessageBox = false}, 10000)
		this.emptyErrorTimer = setInterval(() => {this.showEmptyErrorMessageBox = false}, 10000)
		this.emailErrorTimer = setInterval(() => {this.showEmailErrorMessageBox = false}, 10000)
		this.errorTimer = setInterval(() => {this.showErrorMessageBox = false}, 10000)
	}
}

