import { render, staticRenderFns } from "./App.vue?vue&type=template&id=6bf0eaee&scoped=true&"
import script from "./App.vue?vue&type=script&lang=ts&"
export * from "./App.vue?vue&type=script&lang=ts&"
import style0 from "./App.vue?vue&type=style&index=0&lang=css&"
import style1 from "./App.vue?vue&type=style&index=1&id=6bf0eaee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bf0eaee",
  null
  
)

component.options.__file = "App.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib'
import { VContent } from 'vuetify/lib'
installComponents(component, {
  VApp,
  VContent,
})
