





























































































































































































































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CustomerBaseComponent extends Vue {
	public customer = setInterval(this.changeCustomer, 10000)
	public customerName = [
		'Commerces',
		'Industries',
		'Restaurants',
		'Résidences de Personnes Âgées',
		'CPE',
		'Multi-Logements',
		'Édifices Municipaux, Gouvernementaux',
		'Bureaux'
	]
	private customerId = 1

	public isCustomerSelected(id: number): string {
		if (this.customerId === id) {
			return 'customer-base-button-selected'
		} else {
			return 'customer-base-button'
		}
	}

	private changeCustomer(): number {
		if (this.customerId === 8) {
			this.customerId = 1
		} else {
			this.customerId++
		}
		return this.customerId
	}
}

