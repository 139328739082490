










































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ResponsabilitiesComponent extends Vue {
	public recommandation = setInterval(this.changeRecommandation, 10000)
	private recommandationId = 1

	public isRecommandationSelected(id: number): string {
		if (this.recommandationId === id) {
			return 'dot-selected'
		} else {
			return 'dot'
		}
	}

	private changeRecommandation(): number {
		if (this.recommandationId === 2) {
			this.recommandationId = 1
		} else {
			this.recommandationId++
		}
		return this.recommandationId
	}
}

