










import { Component, Vue } from 'vue-property-decorator'
import NavigationMenuComponent from '@/components/NavigationMenu.vue'

@Component({
	components: {
		NavigationMenu: NavigationMenuComponent
	}
})
export default class Home extends Vue {}

