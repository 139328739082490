






















































































































































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'

@Component
export default class ServicesComponent extends Vue {
	public service = 1
	public serviceInterval = setInterval(this.changeService, 10000)
	public soumission = false
	public isLoading = false
	public errorBox = false
	public errorBoxMessage = ''
	public successBox = false
	public name = ''
	public email = ''
	public phone = ''
	public entreprise = ''
	public address = ''
	public city = ''
	public category = 'none'
	public message = ''

	public sendSoumission(): void {
		this.successBox = false
		this.errorBox = false
		// We first need to make sure all inputs are completed.
		if (this.name === '' ||
			this.email === '' ||
			this.phone === '' ||
			this.entreprise === '' ||
			this.address === '' ||
			this.city === '' ||
			this.category === 'none' ||
			this.message === '') {
			// We show an error message for the user.
			this.errorBox = true
			this.errorBoxMessage = 'Assurez-vous d\'avoir remplis tous les champs du formulaire.'
		} else if (!(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email))) {
			// We test if the email address is valid.
			// If not we show an error message.
			this.errorBox = true
			this.errorBoxMessage = 'Veuillez entrer une adresse courriel valide.'
		} else {
			// While we are sending the soumission, we show a loading icon.
			this.isLoading = true
			// We send a post request with axios to our php file.
			axios.post('lib/soumission.php', {
				name: this.name,
				email: this.email,
				phone: this.phone,
				entreprise: this.entreprise,
				address: this.address,
				city: this.city,
				category: this.category,
				message: this.message
			})
			.then((response) => {
				// Once the mail is send, we can stop the loading icon.
				// Then show a success message to the user.
				this.isLoading = false
				this.successBox = true
				console.log(response)
			})
			.catch((error) => {
				this.isLoading = false
				this.errorBox = true
				this.errorBoxMessage = 'Il y a eu une erreur lors de l\'envoie de la soumission. Réessayer plus tard !'
				console.log(error)
			})
			// We also need to reset our input fields.
			this.name = ''
			this.email = ''
			this.phone = ''
			this.entreprise = ''
			this.address = ''
			this.city = ''
			this.category = 'none'
			this.message = ''
		}
	}

	private changeService(): number {
		if (this.service === 4) {
			this.service = 1
		} else {
			this.service++
		}
		return this.service
	}
}

