import Vue from 'vue'
import '@/plugins/vuetify'
import App from '@/App.vue'
import router from '@/router'

Vue.config.productionTip = false

const root = new Vue({
	router,
	render: (h) => h(App)
})

document.addEventListener('DOMContentLoaded', function() {
	root.$mount('#app')
	document.dispatchEvent(new Event('render-event'))
})
