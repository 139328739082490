





















import { Component, Vue } from 'vue-property-decorator'
import AboutComponent from '@/components/About.vue'
import CustomerBaseComponent from '@/components/CustomerBase.vue'
import ResponsabilitiesComponent from '@/components/Responsabilities.vue'
import ServicesComponent from '@/components/Services.vue'
import ContactComponent from '@/components/Contact.vue'

@Component({
	components: {
		About: AboutComponent,
		CustomerBase: CustomerBaseComponent,
		Responsabilities: ResponsabilitiesComponent,
		Services: ServicesComponent,
		Contact: ContactComponent
	}
})
export default class Home extends Vue {}

